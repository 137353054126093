import React from "react";
import ReactDOM from "react-dom";

export function renderApp(App) {
  if (typeof document !== "undefined") {
    const renderMethod = module.hot
      ? ReactDOM.render
      : ReactDOM.hydrate || ReactDOM.render;
    const render = Comp => {
      renderMethod(<Comp />, document.getElementById("root"));
    };
    render(App);
  }
  // Hot Module Replacement
  if (module.hot) {
    module.hot.accept("../App", () => render(require("../App").default));
  }
}
