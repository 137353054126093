"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

exports.__esModule = true;
exports.media = exports.sizes = exports.Image = exports.Heading = exports.Text = exports.Flex = exports.Box = void 0;

var _styledSystem = require("styled-system");

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _screenSize = require("../antd/themes/screenSize"); // import { styled, css as styledCss } from "../styles/styled";
// import styled from "@emotion/styled";
// import { css as styledCss} from "@emotion/core";


var css = function css(props) {
  return props.css;
};

var themed = function themed(key) {
  return function (props) {
    return props.theme[key];
  };
};

var Box = (0, _styledComponents.default)("div")(_styledSystem.space, _styledSystem.width, _styledSystem.fontSize, _styledSystem.color, _styledSystem.flex, _styledSystem.order, _styledSystem.alignSelf, themed("Box"), css);
exports.Box = Box;
var Flex = (0, _styledComponents.default)(Box)({
  display: "flex"
}, _styledSystem.flexWrap, _styledSystem.flexDirection, _styledSystem.alignItems, _styledSystem.justifyContent, themed("Flex"));
exports.Flex = Flex;
var Text = (0, _styledComponents.default)(Box)(_styledSystem.fontFamily, _styledSystem.fontWeight, _styledSystem.textAlign, _styledSystem.lineHeight, _styledSystem.letterSpacing, themed("Text"));
exports.Text = Text;
var Heading = (0, _styledComponents.default)(Text)(themed("Heading"));
exports.Heading = Heading;
Heading.defaultProps = {
  as: "h2",
  m: 0,
  fontSize: 4,
  fontWeight: "bold"
};

var Image = _styledComponents.default.img.withConfig({
  componentId: "nnrcet-0"
})(["display:inline-block;max-width:100%;font-style:italic;vertical-align:middle;"]);

exports.Image = Image;
var sizes = {
  screenXs: _screenSize.screenXs,
  screenSm: _screenSize.screenSm,
  screenMd: _screenSize.screenMd,
  screenLg: _screenSize.screenLg,
  screenXl: _screenSize.screenXl,
  screenXxl: _screenSize.screenXxl
}; // export const media = (maxWidth, ...args) => styledCss`
//     @media (max-width: ${maxWidth / 16}em) {
//       ${styledCss(...args)}
//     }
//   `
// ;

exports.sizes = sizes;
var media = Object.keys(sizes).reduce(function (acc, label) {
  acc[label] = function () {
    return (0, _styledComponents.css)(["@media  screen and (max-width:", "){", "}"], sizes[label], _styledComponents.css.apply(void 0, arguments));
  };

  return acc;
}, {});
exports.media = media;