"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.BasicLayout = void 0;

var _taggedTemplateLiteralLoose2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteralLoose"));

require("antd/lib/layout/style");

var _layout = _interopRequireDefault(require("antd/lib/layout"));

var _react = _interopRequireDefault(require("react"));

var _SiteFooter = require("./SiteFooter");

var _styled = require("../../styles/styled");

var _Core = require("../../components/Core");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["padding: 6px 6px 0"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteralLoose2.default)(["padding: 12px 12px 0"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Header = _layout.default.Header,
    Content = _layout.default.Content,
    Footer = _layout.default.Footer;
var StyledHeader = (0, _styled.styled)(function (props) {
  return _react.default.createElement(Header, props);
}).withConfig({
  componentId: "t24tgj-0"
})(["&&&{padding:0 0px;}"]);
var StyledContent = (0, _styled.styled)(function (props) {
  return _react.default.createElement(Content, props);
}).withConfig({
  componentId: "t24tgj-1"
})(["padding:24px 24px 0;", " ", " height:100%;"], _Core.media.screenSm(_templateObject()), _Core.media.screenXs(_templateObject2()));

var BasicLayout = function BasicLayout(props) {
  var children = props.children,
      header = props.header,
      siteStore = props.siteStore;
  return _react.default.createElement(_layout.default, null, _react.default.createElement(StyledHeader, null, header && header), _react.default.createElement(StyledContent, {
    style: {
      height: "100%"
    }
  }, children), _react.default.createElement(Footer, {
    style: {
      textAlign: "center"
    }
  }, _react.default.createElement(_SiteFooter.SiteFooter, {
    siteStore: siteStore
  })));
};

exports.BasicLayout = BasicLayout;