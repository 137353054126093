export {
  default as DownOutline,
} from "@ant-design/icons/lib/outline/DownOutline";

export {
  default as FacebookOutline,
} from "@ant-design/icons/lib/outline/FacebookOutline";

export {
  default as TwitterOutline,
} from "@ant-design/icons/lib/outline/TwitterOutline";

export {
  default as RightOutline,
} from "@ant-design/icons/lib/outline/RightOutline";
