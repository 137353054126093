"use strict";

exports.__esModule = true;
exports.config = void 0;
var config = {
  blogPostDir: "posts",
  // The name of directory that contains your posts.
  siteTitle: "Travel Webway",
  // Site title.
  siteTitleAlt: "",
  // Alternative site title for SEO.
  // siteLogo: "/icons/icon-512.png", // Logo used for SEO and manifest.
  siteUrl: "https://travelwebway.com",
  // Domain of your website without pathPrefix.
  pathPrefix: "/",
  // Prefixes all links. For cases when deployed to example.github.io/gatsby-material-starter/.
  siteDescription: "Travel Tips, Tricks, Tools and Reviews",
  // Website description used for RSS feeds/meta description tag.
  backgroundColor: "#fff",
  themeColor: "#0058A8",
  manifestPath: "/manifest.json",
  // siteRss: "/rss.xml", // Path to the RSS file.
  userTwitter: "@travelwebway",
  // siteFBAppID: "", // FB Application ID for using app insights
  // userFacebook: "travelwebway",
  siteGATrackingID: "UA-54389315-11",
  // Tracking code ID for google analytics.
  // postDefaultCategoryID: "General", // Default category for posts.
  // userName: "TravelWebway", // Username to display in the RSS feed.
  amazonTrackingId: "get-coffee-20" // copyright: "Copyright © Travel Webway", // Copyright string for the footer of the website and RSS feed.

};
exports.config = config;