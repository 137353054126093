"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.TravelWebwayThemeProvider = void 0;

var _react = _interopRequireDefault(require("react"));

var _antdLessVariables = require("./lightBlue/antdLessVariables");

var _ThemeProvider = require("../../styles/themes/ThemeProvider");

var theme = {
  primaryColor: _antdLessVariables.primaryColor
};

var TravelWebwayThemeProvider = function TravelWebwayThemeProvider(props) {
  return _react.default.createElement(_ThemeProvider.ThemeProvider, {
    theme: theme
  }, props.children);
};

exports.TravelWebwayThemeProvider = TravelWebwayThemeProvider;