import { renderApp } from "./react-static/renderApp";
// import {renderApp} from "finx/lib/react-static/renderApp";

// import App from "finx/lib/site/eldarlabs/react-static";
// export default App;

// Your top level component
import App from "./App";

// Export your top level component as JSX (for static rendering)
export default App;

renderApp(App);
