
import universal, { setHasBabelPlugin } from '/Users/overseer/n/s/travelwebwayreact/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../node_modules/finx/lib/site/travel/pages/404'), universalOptions)
const t_1 = universal(import('../src/pages/shaving-your-head-while-traveling.jsx'), universalOptions)
const t_2 = universal(import('../node_modules/finx/lib/site/travel/pages'), universalOptions)
const t_3 = universal(import('../node_modules/finx/lib/site/travel/pages/trip/planner'), universalOptions)
const t_4 = universal(import('../node_modules/finx/lib/site/travel/pages/trip/pre-travel-checklist'), universalOptions)
const t_5 = universal(import('../node_modules/finx/lib/site/travel/pages/posts'), universalOptions)
const t_6 = universal(import('../node_modules/finx/lib/site/travel/pages/postPages'), universalOptions)
const t_7 = universal(import('../node_modules/finx/lib/site/travel/pages/packing/category'), universalOptions)
const t_8 = universal(import('../node_modules/finx/lib/site/travel/pages/packing/plan-for'), universalOptions)
const t_9 = universal(import('../node_modules/finx/lib/site/travel/pages/packing/item-type/passport'), universalOptions)
const t_10 = universal(import('../node_modules/finx/lib/site/travel/pages/packing/category/thingTypesInCategoryTemplate'), universalOptions)
const t_11 = universal(import('../node_modules/finx/lib/site/travel/pages/packing/plan-for/thingTypesInPlanForTemplate'), universalOptions)
const t_12 = universal(import('../node_modules/finx/lib/site/travel/pages/tos'), universalOptions)
const t_13 = universal(import('../node_modules/finx/lib/site/travel/pages/disclaimer'), universalOptions)


// Template Map
export default {
  '../node_modules/finx/lib/site/travel/pages/404': t_0,
'../src/pages/shaving-your-head-while-traveling.jsx': t_1,
'../node_modules/finx/lib/site/travel/pages': t_2,
'../node_modules/finx/lib/site/travel/pages/trip/planner': t_3,
'../node_modules/finx/lib/site/travel/pages/trip/pre-travel-checklist': t_4,
'../node_modules/finx/lib/site/travel/pages/posts': t_5,
'../node_modules/finx/lib/site/travel/pages/postPages': t_6,
'../node_modules/finx/lib/site/travel/pages/packing/category': t_7,
'../node_modules/finx/lib/site/travel/pages/packing/plan-for': t_8,
'../node_modules/finx/lib/site/travel/pages/packing/item-type/passport': t_9,
'../node_modules/finx/lib/site/travel/pages/packing/category/thingTypesInCategoryTemplate': t_10,
'../node_modules/finx/lib/site/travel/pages/packing/plan-for/thingTypesInPlanForTemplate': t_11,
'../node_modules/finx/lib/site/travel/pages/tos': t_12,
'../node_modules/finx/lib/site/travel/pages/disclaimer': t_13
}

export const notFoundTemplate = "../node_modules/finx/lib/site/travel/pages/404"
