// Imports


// Plugins
const plugins = [{
  location: "/Users/overseer/n/s/travelwebwayreact/node_modules/react-static-plugin-styled-components",
  plugins: [],
  hooks: {}
},
{
  location: "/Users/overseer/n/s/travelwebwayreact/node_modules/react-static-plugin-mdx",
  plugins: [],
  hooks: {}
},
{
  location: "/Users/overseer/n/s/travelwebwayreact/node_modules/react-static-plugin-less",
  plugins: [],
  hooks: {}
},
{
  location: "/Users/overseer/n/s/travelwebwayreact",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins