"use strict";

exports.__esModule = true;
exports.modifyVars = exports.menuHighlightColor = exports.fontSizeBase = exports.primaryColor = void 0; // antd theme override variables

var primaryColor = "#0058A8";
exports.primaryColor = primaryColor;
var fontSizeBase = "20px";
exports.fontSizeBase = fontSizeBase;
var menuHighlightColor = "#5ce8ff";
exports.menuHighlightColor = menuHighlightColor;
var modifyVars = {
  "link-decoration": "underline",
  "primary-color": primaryColor,
  "font-size-base": fontSizeBase,
  "layout-body-background": "#fff",
  "layout-header-height": "50px",
  "layout-header-background": primaryColor,
  "menu-highlight-color": menuHighlightColor // "@border-radius-base": "6px",
  // "@border-radius-sm": "3px",
  // "background-color-base": "#fff",
  // "background-color-light": "#fff",

};
exports.modifyVars = modifyVars;