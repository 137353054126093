import React from "react";
import { Root, Routes, Head } from "react-static";
import { GTags } from "finx/lib/analytics/gtags";
import { MainLayout } from "finx/lib/site/travel/layouts/MainLayout";
import { config } from "finx/lib/site/travel/data/SiteConfig";
import { isProduction } from "finx/lib/app/production";

class App extends React.Component {
  render() {
    return (
      <Root>
        {isProduction() && (
          <GTags Head={Head} trackingId={config.siteGATrackingID} />
        )}
        <MainLayout siteConfig={config}>
          <Routes />
        </MainLayout>
      </Root>
    );
  }
}

export default App;
